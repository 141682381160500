const Footer = () => {
    return (
        <div className="section bg-dark">
            <div className="container">
                <div className="footer-info-box text-center">
                    <h4 className="title">Let’s work together</h4>
                    <h2 className="mail">
                        <a href="https://sphoun.ca#contact">sphoun@sphoun.ca</a>
                    </h2>
                </div>

                <div className="footer-copyright-social">
                    <div className="footer-copyright">
                        <p>
                            &copy; 2022 <a
                                href="https://sphoun.ca/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Sphoun
                            </a> Made with{" "}
                            <i className="icofont-heart" aria-hidden="true"></i>{" "}
                        </p>
                    </div>
                    <div className="footer-social">
                        <ul className="social">
                            <li>
                                <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className="twitter"
                                    href="#"
                                    onClick={(e)=>{e.preventDefault();console.log("I did nothing")}}
                                >
                                    Twitter
                                </a>
                            </li>
                            <li>
                                <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className="behance"
                                    href="#"
                                    onClick={(e)=>{e.preventDefault();console.log("I did nothing")}}
                                >
                                    Facebook
                                </a>
                            </li>
                            <li>
                                <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className="dribbble"
                                    href="https://dribbble.com/"
                                    onClick={(e)=>{e.preventDefault();console.log("I did nothing")}}
                                >
                                    Instagram
                                </a>
                            </li>
                            <li>
                                <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className="github"
                                    href="https://tiktok.com/"
                                    onClick={(e)=>{e.preventDefault();console.log("I did nothing")}}
                                >
                                    TikTok
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
