const HomeAbout = () => {
    return (
        <div className="section bg-dark overflow-hidden">
            <div className="about-section section-padding mt-n3 mb-n1">
                <div
                    className="about-images"
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/images/mugatu.jpg)`,
                    }}
                ></div>

                <div className="container">
                    <div
                        className="row justify-content-end"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                    >
                        <div className="col-lg-6">
                            <div className="about-content">
                                <h3 className="title">Mugatu Phoun</h3>
                                <p className="about-text">
                                    Founded in Laos in 1955 by Mugatu, Phoun
                                    Design Studios (SDS) has grown to
                                    become one of Asia's leading designers.
                                </p>
                                <p>
                                    We specialise in complex and prestigious
                                    infrastructure design. <br /> <br />{" "}
                                    Our portfolio includes some
                                    of the region’s most iconic landmarks, from
                                    super high-rise luxury developments, to five
                                    star hotels, hospitals and intricately
                                    sophisticated smart buildings.{" "}
                                </p>
                                <p>
                                    We don't limit ourselves only to design in the physical space but also provide our services digitally as well.
                                </p>
                                {/*<img
                                    className="signechar"
                                    src="./images/signechar.png"
                                    alt=""
                                />*/}
                                <h6 className="name">S. Phoun</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeAbout;
