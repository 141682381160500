import axios from 'axios'
import toast from 'react-hot-toast'

const ProjectForm = () => {
    // const { register, handleSubmit, errors } = useForm({
    //     mode: "onBlur",
    // });
    // const onSubmit = (data) => console.log(data);
    var name, phone, email, message;
    var errors = {}
    errors.error = false;

    const handleSubmit = (e) => {
      e.preventDefault();
      if (name === undefined) {
        errors.error=true;
        errors.name = {};
        errors.name.message = "Name cannot be empty"
      } else if (email === undefined) {
        errors.error=true;
        errors.email = {};
        errors.email.message = "Email cannot be empty"
      } else if (phone === undefined) {
        errors.error=true;
        errors.phone = {};
        errors.phone.message = "Phone cannot be empty"
      } else if (message === undefined) {
        errors.error=true;
        errors.message = {};
        errors.message.message = "Message cannot be empty"
      } else {
          if(errors.error){
            errors.error = false;
            if(errors.name) errors.name.message = "";
            if(errors.phone) errors.name.phone = "";
            if(errors.email) errors.name.email = "";
            if(errors.name) errors.name.message = "";
          }
          const data = {
            name: name,
            message: message,
            phone: phone,
            email: email
          }
          const headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Accept': 'application/json',
            "Access-Control-Allow-Origin": "*",
          }
          const json = JSON.stringify(data);
          try{
            toast.promise(
              axios.post('https://api.sphoun.ca/contact', json),{
                loading: "Sending email...",
                success: <b>Email sent successfully!</b>,
                error: <b>Email couldn't be sent :(</b>
              }
            )
          } catch(error) {
            console.log(error);
          }
      }
    }

    return (
        <form onSubmit={(e) => {handleSubmit(e)}}>
            <div className="row gx-10">
                <div className="col-md-4">
                    <div className="single-form">
                        <input
                            type="text"
                            name="name"
                            placeholder="Enter your name..."
                            value={name}
                            onChange={(e) => {name = e.target.value}}
                        />
                        {errors.error && <p>{errors.name.message}</p>}
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="single-form">
                        <input
                            type="email"
                            name="email"
                            placeholder="youemail@domain.com"
                            value={email}
                            onChange={(e) => {email = e.target.value}}
                        />
                        {errors.error && <p>{errors.email.message}</p>}
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="single-form">
                        <input
                            type="text"
                            name="phone"
                            placeholder="phone #"
                            value={phone}
                            onChange={(e) => {phone = e.target.value}}
                        />
                        {errors.error && <p>{errors.phone.message}</p>}
                    </div>
                </div>
              </div>
              <div className="row gx-10">
                <div className="col-md-12">
                    <div className="single-form">
                        <textarea
                            name="message"
                            placeholder="Type your message here"
                            value={message}
                            onChange={(e) => {message = e.target.value}}
                            type="text"
                        ></textarea>
                        {errors.error && <p>{errors.message.message}</p>}
                    </div>
                </div>
              </div>
              <div className="row gx-10">
                <div className="form-btn">
                    <button type="submit">Send Message</button>
                </div>
              </div>
        </form>
    );
};

export default ProjectForm;
